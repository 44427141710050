@import "https://cdn.syncfusion.com/ej2/20.2.36/material.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@import "./color_variables.scss";
@import "./labels.scss";
@import "./syncfusion-components.scss";
@import "./syncfusion-grid.scss";
@import "./breakpoints_variables.scss";
@import "./syncfusion-components.scss";
@import "./syncfusion-grid.scss";

* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  font-size: calc(16px);
  background-color: #fafafa;
}

.e-grid {
  border-width: 1px !important;
  border-style: none solid solid;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  margin-bottom: 0px;
  opacity: initial;
  width: 230px;
  padding: 1px 6px 0px 6px;
}

.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  margin-top: 2px;
  margin-bottom: 2px;
}

.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus)
  input:not(:focus):valid
  ~ label.e-float-text.e-label-bottom,
.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(
    .e-input-focus
  )
  input:not(:focus):valid
  ~ label.e-float-text.e-label-bottom,
.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus)
  input:valid
  ~ label.e-float-text.e-label-bottom,
.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(
    .e-input-focus
  )
  input:valid
  ~ label.e-float-text.e-label-bottom,
.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus)
  input:not(:focus):not(:valid)
  ~ label.e-float-text,
.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus)
  input:not(:focus):not(:valid):not(.e-valid-input)
  ~ label.e-float-text,
.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(
    .e-input-focus
  )
  input:not(:focus):not(:valid):not(.e-valid-input)
  ~ label.e-float-text {
  line-height: 40px;
}

.e-pager .e-pagercontainer {
  margin: 0px 6px 3px 18px;
}

.e-pager div.e-parentmsgbar {
  padding: 14px 18px 0 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  min-height: 35px;
  vertical-align: middle;
  width: auto;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  padding: 0px 10px 0px !important;
}

.e-grid .e-filtermenudiv {
  padding: 3px 6px 3px 6px;
}

.pending-content {
  min-height: 10em;
  background: $neutral-light-color;
  color: $main-color;
  font-size: large;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $pepper-color;
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
.e-tab
  .e-tab-header
  .e-hscroll:not(.e-scroll-device)
  .e-scroll-nav
  .e-nav-arrow {
  color: $white-color !important;
  &:hover {
    color: $pepper-color;
  }
}

.tab-title {
  text-transform: capitalize !important;
}

.e-tab .e-tab-header {
  background: $secondary-color;
}

//TAB TITLE STYLE
.e-tab .e-tab-header .e-tab-text,
.e-tab .e-tab-header .e-tab-icon {
  opacity: 0.5;
}

//SELECTED TAB TITLE STYLE
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  opacity: 1;
}

.e-tab .e-tab-header .e-indicator {
  background-color: $pepper-color;
  border: solid 2px $pepper-color !important;
}

.basic-label {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.edit-shadow {
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  justify-items: center;
}

th.mat-header-cell,
.mat-cell {
  justify-content: center;
  text-align: center;
}

[hidden] {
  display: none !important;
}

.url-link {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer !important;
}

.gm-style-iw,
.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
  padding: 0;
}

.gm-style .gm-style-iw.gm-style-iw-c {
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  overflow: auto;
}

.gmnoprint .gm-style-cc,
.gm-style-cc,
a[rel="noopener"] {
  display: none !important;
}

[class$="api-load-alpha-banner"] {
  display: none;
}


.logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;

  &_image {
    padding-bottom: 25px;
  }

  &_animation {
    border: 6px solid $neutral-light-color;
    border-top: 6px solid $pepper-color;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  &_label {
    padding-top: 10px;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }
}

html,body{
  max-width: 100vw;
}

#sidebar-menu span.menu-item-text{
  font-size: 15px !important;
}

.horizon-section-header{
  min-height: 36px !important;
}

.horizon-section-header > span{
  font-size: 18px !important;
  font-weight: 400 !important;
  height: 36px;
  line-height: 36px !important;
  padding: 0px 18px;
  text-transform: capitalize !important;
}

.e-dialog .e-dlg-header-content{
  padding: 10px 18px;
}

.e-toolbar-items{
  padding: 0 10px;
}

horizon-footer div.footer__copyright.horizontal > horizon-icon{
  display: flex;
}

horizon-footer div.footer__copyright.horizontal > horizon-icon > span{
  margin-top: auto;
  margin-bottom: auto
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: $size-sm) {
  .row{
    margin-right: 0;
    margin-left: 0;
  }
  #app-loader > div.logo-container_image > img{
    width: 300px;
  }
  div.header-wrapper__logo-section horizon-icon svg{
    width: 200px;
  }
  .horizon-section-header{
    min-height: 48px !important;
  }
  
  .horizon-section-header > span{
    height: 48px;
    line-height: 48px !important;
  }
  .footer__copyright.horizontal{
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  horizon-footer ul.footer__navigation.horizontal{
    justify-content: center !important;
  }
}
