@import "./labels.scss";
@import "./color_variables.scss";

//SYNCFUSION TABS
// ----------------------------------------------------------------------------------------------------------------------
.top-level-tabs {
  &.e-tab .e-tab-header {
    background-color: $complementary-color;
  }
  &.e-tab .e-content {
    background-color: #fafafa;
  }
}

//TAB TITLE STYLE
.e-tab .e-tab-header .e-tab-text,
.e-tab .e-tab-header .e-tab-icon {
  color: $white-color;
  opacity: 0.5;
  text-transform: capitalize !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: $white-color;
}

//SELECTED TAB TITLE STYLE
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  opacity: 1;
  &:hover {
    color: $white-color;
  }
}

.e-tab-wrap:hover,
.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  color: $white-color !important;
  @extend .copy-medium;
}

.e-tab .e-tab-header .e-indicator {
  background: $pepper-color;
}

//SYNCFUSION ACCORDION
// ----------------------------------------------------------------------------------------------------------------------
.e-accordion {
  border: none;
}

//ACCORDION HEADER
.e-accordion
  .e-acrdn-item
  .e-acrdn-header
  .e-toggle-icon
  .e-tgl-collapse-icon.e-icons {
  color: $white-color !important;
}

.e-accordion .e-acrdn-item .e-acrdn-header {
  min-height: 30px;
  height: 30px;
  padding: 0;
}

.e-accordion
  .e-acrdn-item.e-select.e-selected.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-icon,
.e-accordion
  .e-acrdn-item.e-select.e-selected.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-content,
.e-accordion
  .e-acrdn-item.e-select.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-icon,
.e-accordion
  .e-acrdn-item.e-select.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-content {
  color: $white-color;
}

.e-accordion .e-acrdn-item > .e-acrdn-header {
  background-color: $complementary-color !important;
  padding: 0 10px !important;
}

//ACCORDION CONTENT
.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content {
  padding: 0;
}

.e-acrdn-header-content {
  color: $white-color !important;
  @extend .h2-medium;
}

//SYNCFUSION DATEPICKER/TEXT INPUT/DROPDOWN
// ----------------------------------------------------------------------------------------------------------------------
//SYNCFUSION ICON SIZE
.e-outline.e-input-group .e-input-group-icon,
.e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
  font-size: 16px;
  margin: 2px 6px 2px 6px;
}

//SYNCFUSION DATEPICKER ICON COLOR
.e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-icons,
.e-input-group-icon {
  color: $main-color !important;
  font-size: 11px !important;
}

//SYNCFUSION ICON COLOR

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
  content: "\e916" !important;
}

.e-input-group-icon.e-spin-up::before {
  content: "\e910" !important;
}

.e-input-group-icon.e-spin-down::before {
  content: "\e916" !important;
}

//SYNCFUSION DATEPICKER/TEXBOX/DROPDOWN INPUT COLOR
.e-outline.e-input-group input.e-input,
.e-outline.e-float-input.e-input-group input,
.e-outline.e-input-group.e-control-wrapper input.e-input,
.e-outline.e-float-input.e-input-group.e-control-wrapper input,
.e-outline.e-float-input input,
.e-outline.e-float-input.e-control-wrapper input,
.e-float-input e-disabled,
.e-input.e-disabled {
  color: $main-color !important;
}

//SYNCFUSION INPUT REMOVE ANIMATION
.e-outline.e-input-group.e-input-focus:hover:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-control-wrapper:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus:hover:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-input-focus.e-control-wrapper.e-float-icon-left:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper.e-float-icon-left:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:hover:not(.e-input-group):not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(
    .e-input-group
  ):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-disabled),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after,
.e-outline.e-input-group.e-input-focus:hover:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-control-wrapper:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus:hover:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-input-focus.e-control-wrapper.e-float-icon-left:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper.e-float-icon-left:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:hover:not(.e-input-group):not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(
    .e-input-group
  ):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-disabled),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled) {
  border-color: none !important;
  box-shadow: none !important;
}

//TEXT INPUT OUTLINE STYLE
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
  height: 25px;
  color: $main-color !important;
  border: 1px solid $neutral-regular-color;
  border-radius: 4px;
  background: $white-color;
  font-size: 11px !important;
}

.e-float-input,
.e-float-input.e-control-wrapper {
  margin-top: 5px;
  padding: 2px 10px 0px 10px;
}

.e-float-input.e-disabled {
  background: none !important;
  border-bottom: 1px solid $neutral-regular-color !important;
  color: $main-color !important;
  padding: 2px 10px 0px 10px;
}

//DROPDOWN LABEL
.e-outline.e-input-group,
.e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(
    .e-float-icon-left
  ):not(.e-input-focus),
.e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-float-icon-left):not(.e-input-focus),
.e-outline.e-input-group.e-float-icon-left:not(.e-input-focus),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(
    .e-input-focus
  ) {
  background-color: $white-color;
  margin-top: 5px;
}

//DROPDOWN LABEL DISABLED
.e-outline.e-input-group.e-disabled:not(.e-input-focus),
.e-outline.e-input-group.e-disabled:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-float-icon-left):not(.e-input-focus),
.e-outline.e-input-group.e-control-wrapper.e-disabled:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
.e-outline.e-input-group.e-disabled.e-float-icon-left:not(.e-input-focus),
.e-outline.e-input-group.e-disabled.e-control-wrapper.e-float-icon-left:not(
    .e-input-focus
  ) {
  background-color: transparent;
  margin-top: 5px;
}

.e-outline.e-input-group input.e-input,
.e-outline.e-input-group input,
.e-outline.e-input-group.e-control-wrapper input.e-input,
.e-outline.e-input-group.e-control-wrapper input {
  padding: 0px 10px 12px 10px;
}

.input.e-outline.e-input,
.e-outline.e-input-group input.e-input,
.e-outline.e-input-group input,
.e-outline.e-input-group.e-control-wrapper input.e-input,
.e-outline.e-input-group.e-control-wrapper input,
.e-outline.e-float-input input.e-input,
.e-outline.e-float-input input,
.e-outline.e-float-input.e-control-wrapper input.e-input,
.e-outline.e-float-input.e-control-wrapper input,
.e-outline.e-input-group:not(.e-float-icon-left) input.e-input:focus,
.e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left)
  input.e-input:focus,
.e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) input:focus,
.e-outline.e-float-input:not(.e-float-icon-left) input:focus,
.e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left).e-input-focus
  input,
.e-outline.e-float-input:not(.e-float-icon-left).e-input-focus input,
.e-outline.e-input-group:not(.e-float-icon-left).e-input-focus input.e-input,
.e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-input-focus
  input.e-input {
  box-sizing: border-box;
  padding: 0px 10px 12px 10px;
}

//CHECKBOX SELECT COLOR
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: $pepper-color;
  color: $white-color !important;
}

//SYNCFUSION DIALOG
// ----------------------------------------------------------------------------------------------------------------------
.e-dialog .e-dlg-header-content {
  background: $neutral-extra-light-color;
}

.e-dialog .e-dlg-header {
  font-size: 16px;
  @extend .h2-medium;
  @extend .dark-blue;
}

.e-dialog .e-dlg-header-content + .e-dlg-content {
  background: $neutral-extra-light-color;
}

//SYNCFUSION FIE UPLOADER
// ----------------------------------------------------------------------------------------------------------------------
.e-upload .e-upload-files .e-upload-file-list,
.e-bigger.e-small .e-upload .e-upload-files .e-upload-file-list {
  min-height: 0;
  border: none;
}

//SYNFUSION CAROUSEL
// ----------------------------------------------------------------------------------------------------------------------

.e-indicator-bar {
  background-color: transparent !important;
}