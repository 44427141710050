@import "~src/styles/color_variables.scss";
@import "~src/styles/labels.scss";

.e-grid {
  border-width: 1px !important;
  border-style: none solid solid;
}

//CELL COLOR
.e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowexpand:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-emptyrow:not(.e-editedbatchcell):not(.e-updatedtd) {
  color: $main-color;
  text-align: right;
}

//HEADER COLOR
.e-grid .e-gridheader {
  color: $neutral-dark-color !important;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  padding: 0px 10px 0px !important;
}

.e-grid.e-resize-lines .e-headercell .e-rhandler,
.e-grid.e-resize-lines .e-headercell .e-rsuppress {
  border: none !important;
}

.e-grid.e-default .e-headercell,
.e-grid.e-default .e-detailheadercell {
  background-color: $neutral-extra-light-color;
}

//NESTED GRID
.e-grid .e-detailcell {
  padding: 0px;
}

// ----------------------------TOOLBAR----------------------------
//BAR COLOR
.e-grid .e-toolbar {
  background-color: $complementary-blue !important;
  border-top: solid 1px $complementary-blue !important;
  height: 35px;
  min-height: 35px;
}

.e-grid .e-toolbar .e-toolbar-items {
  background-color: transparent !important;
  height: 35px;
  min-height: 35px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  background-color: transparent !important;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper {
  padding: 0;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  margin-bottom: 0px;
  opacity: initial;
  width: 230px;
  padding: 2px 6px 0px 6px;
}

//BAR ACTION BUTTONS
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px !important;
}
.e-toolbar .e-toolbar-items:first-child > .e-toolbar-item:last-child,
.e-toolbar
  .e-toolbar-items:first-child
  > .e-toolbar-right
  .e-toolbar-item:last-child,
.e-toolbar-items:first-child > .e-toolbar-left .e-toolbar-item:last-child {
  margin-right: 3px;
  padding: 0px 2px 1px 2px;
}

#assignTo {
  width: 100% !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 5px;
  margin-right: 10px;
  padding: 0px;
}

//ACTION ICON COLOR
.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn.e-btn.e-tbtn-txt
  .e-icons.e-btn-icon {
  color: $white-color !important;
  line-height: 0px !important;
  min-height: 0px !important;
}

//ACTION TEXT COLOR
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: $white-color !important;
  margin-left: 5px;
  @extend .copy-regular;
}

//ACTION ICONS
.e-grid .e-icon-grightarrow::before,
.e-grid-menu .e-icon-grightarrow::before {
  content: url("../assets/icon/grouping_right_arrow.svg");
}

.e-grid .e-icon-gdownarrow::before,
.e-grid-menu .e-icon-gdownarrow::before {
  content: url("../assets/icon/grouping_down_arrow.svg");
}

.e-custom-icons.e-add::before {
  content: url("../assets/icon/add.svg");
}

.e-custom-icons.e-edit::before {
  content: url("../assets/icon/edit.svg");
}

.e-custom-icons.e-delete::before {
  content: url("../assets/icon/delete.svg");
}

.e-custom-icons.e-refresh::before {
  content: url("../assets/icon/refresh.svg");
}

.e-custom-icons.e-bar::before {
  content: url("../assets/icon/bar.svg");
}

.e-custom-icons.e-assign-to::before {
  content: url("../assets/icon/assignto.svg");
}

.e-custom-icons.e-replicate::before {
  content: url("../assets/icon/replicate.svg");
}
.e-custom-icons.e-history::before {
  content: url("../assets/icon/history.svg");
}

.e-custom-icons.e-export::before {
  content: url("../assets/icon/export.svg");
}

// ----------------------------PAGINATION----------------------------

.e-grid .e-pager {
  background-color: $neutral-extra-light-color;
}

.e-pager .e-pagercontainer {
  background-color: transparent !important;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: $pepper-color !important;
}

.e-pager .e-numericitem {
  color: $main-color !important;
  background-color: transparent;
}

.e-pager .e-numericitem.e-active {
  color: $white-color !important;
}

.e-pager div.e-icons.e-pager-default {
  color: $main-color !important;
  font-weight: bold;
  background-color: transparent !important;
}

.e-pager div.e-parentmsgbar {
  color: $main-color !important;
}

// ----------------------------GROUPS----------------------------

.e-grid .e-groupheadercell .e-icons::before {
  color: $white-color;
}

.e-grid .e-grouptopleftcell {
  background-color: $neutral-extra-light-color;
}

.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse {
  background-color: $neutral-light-color;
  padding: 4px 10px;
}

.e-grid .e-groupdroparea {
  background: $tertiary-color;
  border-top-color: $main-color;
  color: $white-color;
  font-family: "Roboto", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  min-height: 30px;
  padding: 8px 14px;
}

.e-grid .e-groupdroparea.e-grouped {
  padding: 0;
  background: $tertiary-color;
}

.e-groupdroparea.e-lib.e-droppable.e-draggable.e-grouped
  .e-grid-icon.e-groupheadercell {
  background-color: $main-color;
}

//Summatory footer
.e-grid .e-summaryrow .e-summarycell,
.e-grid .e-summaryrow .e-templatecell,
.e-grid .e-summarycontent .e-indentcell,
.e-grid .e-indentcell.e-detailindentcelltop {
  background-color: $secondary-color !important;
  color: $main-color !important;
  padding: 5px 21px !important;
}

.e-grid td.e-active {
  background-color: $neutral-light-color-50perc !important;
}

.e-grid td.e-cellselectionbackground {
  background-color: $complementary-color;
}

.e-grid.e-gridhover
  tr[role="row"]:not(.e-editedrow):not(.e-detailrow):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell),
.e-grid.e-gridhover
  tr[role="row"]:not(.e-detailrow):hover
  .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell),
.e-grid.e-gridhover
  tr[role="row"]:hover
  .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell),
.e-grid.e-rtl
  .e-gridhover
  tr[role="row"]:hover
  .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell),
.e-grid.e-gridhover
  tr[role="row"]:not(.e-detailrow):hover
  .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell) {
  background-color: $neutral-light-color-50perc;
}

// ---------------------------- FILTERS ----------------------------
.e-grid .e-icon-filter::before {
  content: url(~src/assets/icon/filter.svg);
}

.e-excelfilter .e-contextmenu-wrapper ul li,
.e-excelfilter .e-contextmenu-container ul li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-dialog .e-dlg-header {
  font-size: 16px;
  @extend .h2-medium;
  @extend .dark-blue;
}

.e-grid .e-filtermenudiv {
  padding: 3px 6px 3px 6px;
}
